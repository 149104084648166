let isAscendent = true;

export function Filter(data: any, search: Event): string {
  const filter = (search.target as HTMLInputElement).value;
  return filter.trim().toLowerCase();
}

export function FilterSelect(data: any, search: string): boolean {
  return data.user_role.note.trim().toLowerCase().includes(search);
}

export function RemoveElementArray(data: any[], elementToRemove: any): any[] {
  const index = data.findIndex(
    (element, index) => element.id === elementToRemove.id
  );
  data.splice(index, 1);
  return data;
}

export function ReplaceElementArray(data: any[], elementUpdated: any): any[] {
  const index = data.findIndex(
    (element, index) => element.id === elementUpdated.id
  );
  data[index] = elementUpdated;
  return data;
}

export function ReplaceElementObjectArray(
  data: any[],
  objectId: number,
  elementUpdated: any,
  objectUpdated: any
): any[] {
  const index = data.findIndex((element, index) => element.id === objectId);
  data[index][objectUpdated] = elementUpdated;
  return data;
}

export function CoordinateStringToNumber(coordinate: any): number {
  return parseFloat(`${coordinate}`);
}

export function GetSortOrder(prop: string) {
  if (isAscendent) {
    isAscendent = false;
    return function (a: any, b: any) {
      if (a[prop] === null) {
        return 1;
      } else if (b[prop] === null) {
        return -1;
      } else if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  } else {
    isAscendent = true;
    return function (a: any, b: any) {
      if (a[prop] === null) {
        return 1;
      } else if (b[prop] === null) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  }
}

export function GetSortOrderObject(keyObject: string, prop: string) {
  if (isAscendent) {
    isAscendent = false;
    return function (a?: any, b?: any) {
      if (a[keyObject] === null) {
        return 1;
      } else if (b[keyObject] === null) {
        return -1;
      } else if (a[keyObject][prop] > b[keyObject][prop]) {
        return 1;
      } else if (a[keyObject][prop] < b[keyObject][prop]) {
        return -1;
      }
      return 0;
    };
  } else {
    isAscendent = true;
    return function (a?: any, b?: any) {
      if (a[keyObject] === null) {
        return 1;
      } else if (b[keyObject] === null) {
        return -1;
      } else if (a[keyObject][prop] < b[keyObject][prop]) {
        return 1;
      } else if (a[keyObject][prop] > b[keyObject][prop]) {
        return -1;
      }
      return 0;
    };
  }
}
