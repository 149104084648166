import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  LocationCategory,
  LocationStatus,
  Location,
} from '@app/core/interfaces/location.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Response } from '@app/core/interfaces/responses/response.interface';

import { environment } from 'src/environments/environment';
import { typeAction } from '@app/shared/utils/enum';

@Injectable()
export class LocationService {
  private apiUrl = environment.urlMsFleet;

  private subjectLocations = new Subject<Location>();
  private subjectLocationsRequest = new Subject<Location>();
  private subjectLocationsDenied = new Subject<Location>();
  public action: typeAction | undefined;

  constructor(private http: HttpClient) {}

  getLocationsList(params?: HttpParams): Observable<Response<Location[]>> {
    return this.http.get<Response<Location[]>>(`${this.apiUrl}/locations`, {
      params: params,
    });
  }

  getLocationsCategoriesList(): Observable<Response<LocationCategory[]>> {
    return this.http.get<Response<LocationCategory[]>>(
      `${this.apiUrl}/location-categories`
    );
  }

  getLocationsStatusList(): Observable<Response<LocationStatus[]>> {
    return this.http.get<Response<LocationStatus[]>>(
      `${this.apiUrl}/location-statuses`
    );
  }

 

  getLocationById(locationId: number): Observable<Response<Location>> {
    return this.http.get<Response<Location>>(
      `${this.apiUrl}/${locationId}.json`
    );
  }

  

  deleteLocation(locationId: number): Observable<Response<Location>> {
    return this.http.delete<Response<Location>>(
      `${this.apiUrl}/${locationId}.json`
    );
  }


  acceptLocation(locationId: number): Observable<Response<Location>> {
    return this.http.put<Response<Location>>(
      `${this.apiUrl}/admin/accept/${locationId}`,
      null
    );
  }

  rejectLocation(
    locationId: number,
    comment: string
  ): Observable<Response<Location>> {
    return this.http.put<Response<Location>>(
      `${this.apiUrl}/admin/reject/${locationId}`,
      comment
    );
  }

  updateLocation(
    locationId: number,
    location: any
  ): Observable<Response<Location>> {
    return this.http.patch<Response<Location>>(
      `${this.apiUrl}/${locationId}`,
      location
    );
  }

  notifyDataSubject(location?: Location, action?: typeAction): void {
    this.action = action;
    this.subjectLocations.next(location);
  }

 

  listenDataSubjectLocations(): Observable<Location> {
    return this.subjectLocations.asObservable();
  }

  listenDataSubjectLocationsRequest(): Observable<Location> {
    return this.subjectLocationsRequest.asObservable();
  }

  listenDataSubjectLocationsDenied(): Observable<Location> {
    return this.subjectLocationsDenied.asObservable();
  }
}
